import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { Link } from "react-router-dom";
import SendingDialog from "../components/SendingDialog";

function AddLearning() {
  const [learning, setLearning] = useState({
    title: "",
    category: "Fundamental Analysis",
    description: "",
  });
  const [fileURL, setFileURL] = useState();
  const [isOpen, setIsOpen] = useState(false);
  console.log(learning.category);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsOpen(true);
    const fileUrl = fileURL;
    const title = learning.title;
    const category = learning.category;
    const description = learning.description;

    const post = { fileUrl, title, category, description };
    // console.log(post)

    try {
      axios
        .post(
          "https://hero-backend-july10-72654a654b93.herokuapp.com/api/learning",
          post
        )
        .then((res) => {
          console.log(res);
          // alert(res.data.message);
          window.location.reload();
          setIsOpen(false);
        })
        .catch((err) => {
          console.log(err);
          alert("Please make sure all fields have been filled out...");
          window.location.reload();
        });

      // console.log(res);
    } catch (error) {
      alert("Please make sure all fields have been filled out...");
      window.location.reload();
    }
  };

  return (
    <div className="h-[100vh]">
      <div className="flex h-[100%]">
        <Sidebar />
        <div className="md:w-[85%] w-[90%] mx-auto md:mx-0 flex items-center justify-center ">
          <form action="" className="md:w-[80%] w-[100%] flex flex-col gap-5">
            <div className="flex flex-col">
              <label htmlFor="">Paste youtube link here</label>
              <input
                className="px-3 py-1.5 border border-black outline-none"
                type="text"
                onChange={(e) => setFileURL(e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="">Title</label>
              <input
                className="px-3 py-1.5 border border-black outline-none"
                type="text"
                onChange={(e) =>
                  setLearning({ ...learning, title: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="">Category</label>
              <select
                className="border border-black py-1.5 px-3"
                onChange={(e) =>
                  setLearning({ ...learning, category: e.target.value })
                }
              >
                <option value="Fundamental Analysis">
                  Fundamental Analysis
                </option>
                <option value="Technical Analysis - Part 1">
                  Technical Analysis - Part 1
                </option>
                <option value="Risk Management">Risk Management</option>
                <option value="Trading Psychology">Trading Psychology</option>
                <option value="Technical Analysis - Part 2">
                  Technical Analysis - Part 2
                </option>
                <option value="Fundamental Analysis - Part 2">
                  Fundamental Analysis - Part 2
                </option>
                <option value="Why FT9ja?">Why FT9ja?</option>
                <option value="Smart Money Concept - Part 1">Smart Money Concept - Part 1</option>
              </select>
              {/* <input
                className="px-3 py-1.5 border border-black outline-none"
                type="text"
                onChange={(e) =>
                  setLearning({ ...learning, category: e.target.value })
                }
              /> */}
            </div>
            <div className="flex flex-col">
              <label htmlFor="">Description</label>
              <textarea
                className="px-3 py-1.5 border border-black outline-none"
                name=""
                id=""
                cols="30"
                rows="5"
                onChange={(e) =>
                  setLearning({ ...learning, description: e.target.value })
                }
              ></textarea>
            </div>
            <button
              onClick={(e) => handleSubmit(e)}
              className="border outline-none bg-[#359602] text-white font-plus font-bold text-lg py-3 w-[303px] px-3 rounded-[10px]"
            >
              Submit
            </button>
          </form>
          <SendingDialog isOpen={isOpen} setIsOpen={setIsOpen} />
          <Link to="/home">
            <button className=" md:hidden fixed bottom-5 right-2 bg-[#359602] text-white text-lg px-2 py-1 rounded-xl">
              Go to home
            </button>
          </Link>
          <Link to="/">
            <p className="text-black text-lg md:hidden top-16 right-2 fixed px-2 text-center py-1 bg-[#FFA500] border border-black rounded-xl cursor-pointer">
              Log out
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AddLearning;
